import { ApplicationPlatformGA4 } from 'Shared/services/analytics/constants';
import { isInAndroidApp, isInIOSApp } from 'Shared/services/mobileAppMessenger';

/**
 * Getting the application platform for GA4
 */
const getApplicationPlatformForGA4 = (): ApplicationPlatformGA4 => {
  if (isInAndroidApp()) {
    return ApplicationPlatformGA4.ANDROID;
  }
  if (isInIOSApp()) {
    return ApplicationPlatformGA4.IOS;
  }
  return ApplicationPlatformGA4.WEBSITE;
};

export {
  getApplicationPlatformForGA4,
  isInAndroidApp,
};