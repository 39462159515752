import { COOKIE_IDS } from 'App/constants';
import { getCookie, setCookie } from 'Shared/services/cookieService';
import { v4 as uuidV4 } from 'uuid';

/**
 * Creates a device id if one doesn't exist
 * @returns string
 */
const initDeviceId = () => {
  let deviceId = getCookie(COOKIE_IDS.DEVICE_ID);

  if (!deviceId) {
    deviceId = uuidV4();
  }

  setCookie(COOKIE_IDS.DEVICE_ID, deviceId, 365, true);

  return deviceId;
};

/**
 * Returns the device id
 * @returns string
 */
const getDeviceId = () => {
  return initDeviceId();
};

export {
  getDeviceId,
  initDeviceId
};