import { APP_ENVIRONMENTS } from 'App/constants';
import { PRE_MEMBER_LOGOUT_URL } from 'App/routes/constants';
import { logError } from 'App/services/coralogixService';
import { getMonolithCookie, getUserId, setMonolithCookie } from 'App/services/idpTokenService';
import { SurveyModel } from 'Shared/models/SurveyModel';
import { MS_API } from 'Shared/services/apiInterface';
import { isMobile } from 'Shared/services/deviceService';
import { isInMobileApp } from 'Shared/services/mobileAppMessenger';
import { MONOLITH_PANEL_ACTIVITIES } from 'Shared/services/monolith/api/__mocks__/localPanelActivities';
import { MONOLITH_LOGIN_ENDPOINT } from 'Shared/services/monolith/constants';
import { SwrKeys } from 'Shared/services/swr/swrKeys';
import useSWR, { SWRResponse } from 'swr';
import useSWRMutation from 'swr/mutation';

type MonolithPanelActivityBase = Omit<SurveyModel, 'image1' | 'internalUrl'>;
export interface CustomMonolithPanelActivity extends MonolithPanelActivityBase {
  image_1: string;
  url    : string;
}

export interface MonolithPanelActivitiesResponse {
  activities: CustomMonolithPanelActivity[];
}

/**
 * Login user and start a session on the monolith
 * @returns Promise<boolean> - true if login was successful, false otherwise
 */
const loginToMonolith = (() => {
  let   currentRequest: Promise<boolean> | null = null;
  const baseURL                                 = process.env.REACT_APP_MONOLITH_BASE_URL;
  const url                                     = MONOLITH_LOGIN_ENDPOINT;

  return (): Promise<boolean> => {
    if (currentRequest) {
      return currentRequest;
    }

    // Don't call it if the cookie already exists
    // Don't call it if member is on the mobile app
    if ((getMonolithCookie() && getMonolithCookie() === getUserId()) || isInMobileApp()) {
      return Promise.resolve(true);
    }

    currentRequest = new Promise<boolean>(resolve => {
      if (!process.env.MONOLITH_SURVEYS_ENABLED) {
        resolve(false);
        return;
      }

      const request = MS_API({
        method         : 'POST',
        baseURL        : baseURL,
        url            : url,
        withCredentials: true
      });

      request.then(() => {
        setMonolithCookie();
        resolve(true);
      });

      request.catch(error => {
        logError('loginToMonolith', error);
        resolve(false);
      });

      request.finally(() => {
        currentRequest = null;
      });
    });

    return currentRequest;
  };
})();

/**
 * Log out of the monolith by calling the logout endpoint.
 * The reason this creates an image instead of calling the
 * endpoint is because /logout doesn't support CORS.
 */
const logoutFromMonolith = (): void => {
  const logoutImage         = document.createElement('img');
  logoutImage.width         = 0;
  logoutImage.height        = 0;
  logoutImage.src           = `${process.env.REACT_APP_MONOLITH_BASE_URL}${PRE_MEMBER_LOGOUT_URL}`;
  logoutImage.style.display = 'none';
};

/**
 * A hook which allows the user to login and start a session on the monolith
 * @returns an SWRMutationResponse where `trigger` logs the user into the monolith
 */
const useLoginToMonolith = () => {
  return useSWRMutation(SwrKeys.MonolithLogin, () => loginToMonolith());
};

/**
 * Get panel activities
 * @returns MonolithPanelActivitiesResponse
 */
const getPanelActivities = async (): Promise<MonolithPanelActivitiesResponse> => {
  const baseURL                   = process.env.REACT_APP_MONOLITH_BASE_URL;
  const userId                    = getUserId();
  const isMobileDevice            = isMobile();
  const formFactor                = isMobileDevice ? 'Mobile' : 'Desktop';
  const PANEL_ACTIVITIES_ENDPOINT = `${baseURL}/api/v1/users/${userId}/activities?form_factor=${formFactor}&has_flash=false&is_mobile=${isMobileDevice}&taken=false&type_id=30`;

  try {
    const response = await fetch(PANEL_ACTIVITIES_ENDPOINT, {
      credentials: 'include',
    });

    return await response.json();
  } catch {
    return { activities: [] };
  }
};

/**
 * Fetcher function for panel activities
 * In local development, it returns the mock data
 * @returns
 */
const panelActivitiesFetcher = async (): Promise<CustomMonolithPanelActivity[] | any> => {
  if (process.env.APP_ENV === APP_ENVIRONMENTS.DEVELOPMENT) {
    return MONOLITH_PANEL_ACTIVITIES.activities;
  } else {
    const data = await getPanelActivities();
    return data.activities || [];
  }
};

/**
 * SWR hook to get monolith panel activities
 * @returns
 */
export const useGetMonolithPanelActivities = (): SWRResponse<CustomMonolithPanelActivity[]> => {
  const swrResponse = useSWR(SwrKeys.MonolithPanelActivities, panelActivitiesFetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false
  });

  return {
    ...swrResponse,
    data: swrResponse.data || [],
    isLoading: !swrResponse.error && !swrResponse.data,
  };
};

export {
  getPanelActivities,
  loginToMonolith,
  logoutFromMonolith,
  MONOLITH_LOGIN_ENDPOINT,
  useLoginToMonolith
};
