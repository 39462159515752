import { COOKIE_IDS } from 'App/constants';
import { getSessionId, getUserId } from 'App/services/idpTokenService';
import { getWurfl } from 'App/services/wurflService';
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES } from 'Shared/services/analytics/constants';
import { analyticsTrackPageView, PageViewEvent } from 'Shared/services/analytics/events/pageView';
import cleanEmptyFields from 'Shared/services/analytics/services/cleanEmptyFields';
import convertBooleansToString from 'Shared/services/analytics/services/convertBooleanToString';
import { getApplicationPlatformForGA4 } from 'Shared/services/analytics/services/mobileWebviewAnalyticsService';
import { canRedeem, hasRedeemed } from 'Shared/services/analytics/services/redeemHelper';
import { getCookie } from 'Shared/services/cookieService';
import { initGtmDatalayerEvents, setGtmUserProperties } from 'Shared/services/gtmService';
import {
  getAllFlagsWithVariationsFromCache
} from 'Shared/services/opa/app/splitTestService';
import { getUserCacheData, UserSessionCacheKeys } from 'Shared/services/sessionCacheService';

export interface AnalyticsEvent {
  event: ANALYTICS_EVENT_NAMES,
  attributes: any
}

const isAnalyticsDebugEnabled = !!getCookie(COOKIE_IDS.ANALYTICS_DEBUG);
let   configTriggered         = false;
let   pageViewTriggered       = false;

/**
 * Accepts analytics events to be passed to GA4 and GTM
 * @params events - list of events to be sent to ga4
 * @returns void
 */
export const analyticsTrack = (events: AnalyticsEvent[]): void => {
  if (!window.gtag) {
    return;
  }

  // Re-initialize GA4/GTM Parameters so that the user configurations
  // are always up to date.
  updateUserProperties();

  events.forEach((event: AnalyticsEvent) => {
    let attributes = cleanEmptyFields(event.attributes) || {};
        attributes = convertBooleansToString(attributes);
    const eventName: ANALYTICS_EVENT_NAMES = event.event;

    window.gtag('event', eventName, {
      'send_to': process.env.GA4_ID,
      'event_callback': () => {
        if (isAnalyticsDebugEnabled) {
          // eslint-disable-next-line no-console
          console.log('SJ GA4', event);
        }
      },
      ...attributes
    });

    initGtmDatalayerEvents(eventName, attributes);
  });
};

/**
 * Start page view watch
 * @returns void
 */
const initPageView = (): void => {
  // Track page view
  let currentURL: string;
  setInterval(() => {
    const newURL = window.location.href;

    if (newURL !== currentURL) {
      const pageViewEvent: PageViewEvent = {
        page_title: document.title,
        page_location: newURL,
        page_referrer: currentURL
      };
      analyticsTrackPageView(pageViewEvent);

      currentURL = newURL;
    }
  }, 100);
};

/**
 * Updates user properties with GA4/GTM so
 * that they are always up to date.
 */
const updateUserProperties = (): void => {
  const userData: any = {};

  const userInfoWithInviteId = getUserCacheData(UserSessionCacheKeys.USER_INFO_WITH_INVITE_ID);
  const userInfoWithDates    = getUserCacheData(UserSessionCacheKeys.USER_INFO_WITH_DATES);
  const userPoints           = getUserCacheData(UserSessionCacheKeys.USER_POINTS);
  const userVerStatus        = getUserCacheData(UserSessionCacheKeys.USER_VERIFICATION_STATUS);
  const id                   = userInfoWithInviteId?.id           || userInfoWithDates?.id           || undefined;
  const affiliate            = userInfoWithInviteId?.affiliate    || userInfoWithDates?.affiliate    || undefined;
  const user_signup_source   = userInfoWithInviteId?.signupSource || userInfoWithDates?.signupSource || undefined;
  const current_points       = userPoints?.currentPoints  || undefined;
  const lifetime_points      = userPoints?.lifetimePoints || undefined;
  const can_redeem           = canRedeem(current_points, userVerStatus?.verificationStatus);
  const has_redeemed         = hasRedeemed(current_points, lifetime_points);

  if (id !== undefined) {
    userData.id = id;
  }

  if (affiliate !== undefined) {
    userData.affiliate = affiliate;
  }

  if (user_signup_source !== undefined) {
    userData.user_signup_source = user_signup_source;
  }

  if (current_points !== undefined) {
    userData.current_points = current_points;
  }

  if (lifetime_points !== undefined) {
    userData.lifetime_points = lifetime_points;
  }

  if (can_redeem !== undefined) {
    userData.can_redeem = can_redeem;
  }

  if (has_redeemed !== undefined) {
    userData.has_redeemed = has_redeemed;
  }

  initAnalytics(userData, false);
};

/**
 * Initialize GA4 Tracking
 * @returns void
 */
export const initAnalytics = (userData: any = {}, extractUserDataFromCookies = false): void => {
  if (!window.gtag) {
    return;
  }

  if (extractUserDataFromCookies) {
    const userId             = getUserId(true);
    userData.id              = userId;
    userData.current_points  = 0;
    userData.lifetime_points = 0;
  }

  const applicationPlatform = getApplicationPlatformForGA4();

  // GA4 Event Config
  // Disabled GA4 auto page_view event by setting send_page_view on Member config
  const config: any = {
    [ANALYTICS_EVENT_ATTRIBUTES.app_region]          : process.env.COUNTRY_BUILD,
    [ANALYTICS_EVENT_ATTRIBUTES.application_platform]: applicationPlatform,
    [ANALYTICS_EVENT_ATTRIBUTES.device_form_factor]  : getWurfl().form_factor,
    [ANALYTICS_EVENT_ATTRIBUTES.send_page_view]      : false,
  };

  // Only set debug mode parameter if debug mode enabled
  if (isAnalyticsDebugEnabled) {
    config[ANALYTICS_EVENT_ATTRIBUTES.debug_mode] = true;
  }

  // GA4 Member Config
  const userConfig: any = {
    [ANALYTICS_EVENT_ATTRIBUTES.app_region]              : process.env.COUNTRY_BUILD,
    [ANALYTICS_EVENT_ATTRIBUTES.application_platform]    : applicationPlatform,
    [ANALYTICS_EVENT_ATTRIBUTES.device_form_factor]      : getWurfl().form_factor,
    [ANALYTICS_EVENT_ATTRIBUTES.user_current_points]     : userData.current_points,
    [ANALYTICS_EVENT_ATTRIBUTES.user_signup_affiliate_id]: userData.affiliate,
    [ANALYTICS_EVENT_ATTRIBUTES.user_total_points]       : userData.lifetime_points,
    [ANALYTICS_EVENT_ATTRIBUTES.user_signup_source]      : userData.user_signup_source,
    [ANALYTICS_EVENT_ATTRIBUTES.can_redeem]              : userData.can_redeem,
    [ANALYTICS_EVENT_ATTRIBUTES.has_redeemed]            : userData.has_redeemed,
    ...getAllFlagsWithVariationsFromCache()
  };

  const sessionId = getSessionId();
  if (sessionId) {
    config[ANALYTICS_EVENT_ATTRIBUTES.sj_session_id]     = sessionId;
    userConfig[ANALYTICS_EVENT_ATTRIBUTES.sj_session_id] = sessionId;
  }

  if (userData.id) {
    config[ANALYTICS_EVENT_ATTRIBUTES.user_id]     = userData.id;
    userConfig[ANALYTICS_EVENT_ATTRIBUTES.user_id] = userData.id;
  } else {
    const userId = getUserId(true);

    if (userId) {
      config[ANALYTICS_EVENT_ATTRIBUTES.user_id]     = userId;
      userConfig[ANALYTICS_EVENT_ATTRIBUTES.user_id] = userId;
    }
  }

  if (userData.user_signup_affiliate_id) {
    config[ANALYTICS_EVENT_ATTRIBUTES.user_signup_affiliate_id]     = userData.affiliate;
    userConfig[ANALYTICS_EVENT_ATTRIBUTES.user_signup_affiliate_id] = userData.affiliate;
  }

  if (typeof userData.current_points !== 'undefined') {
    config[ANALYTICS_EVENT_ATTRIBUTES.user_current_points]     = userData.current_points;
    userConfig[ANALYTICS_EVENT_ATTRIBUTES.user_current_points] = userData.current_points;
  }

  if (typeof userData.lifetime_points !== 'undefined') {
    config[ANALYTICS_EVENT_ATTRIBUTES.user_total_points]     = userData.lifetime_points;
    userConfig[ANALYTICS_EVENT_ATTRIBUTES.user_total_points] = userData.lifetime_points;
  }

  if (typeof userData.user_signup_source !== 'undefined') {
    config[ANALYTICS_EVENT_ATTRIBUTES.user_signup_source]     = userData.user_signup_source;
    userConfig[ANALYTICS_EVENT_ATTRIBUTES.user_signup_source] = userData.user_signup_source;
  }

  // Setup the global event attributes
  if (!configTriggered && !extractUserDataFromCookies) {
    configTriggered = true;
    window.gtag('config', process.env.GA4_ID, config);
  }

  if (!pageViewTriggered) {
    pageViewTriggered = true;
    initPageView();
  }

  // Setup GA4 user config
  window.gtag('set', ANALYTICS_EVENT_NAMES.user_properties, userConfig);

  // Setup GTM user config
  setGtmUserProperties(userConfig);
};
