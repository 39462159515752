import { getSurveyDevice, getUspDevice } from 'Shared/services/deviceService';
import { isInAndroidApp, isInIOSApp } from 'Shared/services/mobileAppMessenger';

interface UserDetails {
  userId  : string;
  userName: string;
}

type UspDeviceType = {
  is_mobile           : boolean,
  complete_device_name: string,
  form_factor         : string
}

enum DeviceType {
  iOS     = 'ios',
  Android = 'android',
  Web     = 'web'
}

/**
 * Gets the user device from the survey cookie
 */
const getUserDevice = (): UspDeviceType | undefined => {
  try {
    return JSON.parse(getSurveyDevice());
  } catch (error) {
    logError('getUserDevice', error);
    return {
      is_mobile           : false,
      complete_device_name: 'UNABLE_TO_RETRIEVE',
      form_factor         : 'UNABLE_TO_RETRIEVE'
    };
  }
};

/**
 * Gets the user device app
 */
const getUserDeviceApp = (): DeviceType => {
  if (isInIOSApp()) {
    return DeviceType.iOS;
  }

  if (isInAndroidApp()) {
    return DeviceType.Android;
  }

  return DeviceType.Web;
};

/**
 * Sets the user context for Coralogix
 */
export const setUserContext = (userDetails: UserDetails): void => {
  const userDevice = getUserDevice();

  window.CoralogixRum?.setUserContext({
    user_id      : userDetails.userId,
    user_name    : userDetails.userName,
    user_metadata: {
      user_device_is_mobile           : userDevice?.is_mobile,
      user_device_complete_device_name: userDevice?.complete_device_name,
      user_device_form_factor         : userDevice?.form_factor,
      user_device_usp                 : getUspDevice(),
      user_device_app                 : getUserDeviceApp(),
    },
  });
};

/**
 * Gets the Coralogix initialization state
 */
export const isCoralogixInitialized = (): boolean => {
  return window.CoralogixRum?.isInited ?? false;
};

/**
 * Logs an error to Coralogix
 * @param message
 * @param data
 * @returns void
 */
export const logError = (message: string, data: any): void => {
  const errorStackOrData = data?.stack ?? data?.message ?? data;
  window.CoralogixRum?.error(message, errorStackOrData);
};

/**
 * Gets the user context from Coralogix
 */
export const getUserContext = () => {
  return window.CoralogixRum?.getUserContext();
};
