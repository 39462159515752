import { MINIMUM_REDEMPTION_POINTS } from 'Shared/constants';
import { userVerificationStatuses } from 'Shared/services/ver/app/verificationService';

/**
 * Check if user can redeem points
 * @param current_points
 * @param verificationStatus
 * @returns boolean | undefined
 */
export const canRedeem = (current_points: number | undefined, verificationStatus: string | undefined): boolean | undefined => {
  if (!current_points || !verificationStatus) {
    return undefined;
  }

  return current_points >= MINIMUM_REDEMPTION_POINTS
    && (verificationStatus === userVerificationStatuses.passed || verificationStatus === userVerificationStatuses.none);
};

/**
 * Check if user has redeemed points
 * @param current_points
 * @param lifetime_points
 * @returns boolean | undefined
 */
export const hasRedeemed = (current_points: number | undefined, lifetime_points: number | undefined): boolean | undefined => {
  if (!current_points || !lifetime_points) {
    return undefined;
  }

  return current_points < lifetime_points;
};
