import { MENU_ROUTES } from 'App/components/sidebar/constants';
import { getSessionId } from 'App/services/idpTokenService';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { ANALYTICS_EVENT_SUB_TYPE } from 'Shared/services/analytics/constants';
import { analyticsTrackLoginEnd } from 'Shared/services/analytics/events/loginEnd';
import { analyticsTrackLoginStart } from 'Shared/services/analytics/events/loginStart';
import { getLocalStorage } from 'Shared/services/localStorageService';

const initLoginFromMonolithTracking = (): void => {
  // Only trigger on the memberh home page
  if (window.location.pathname !== MENU_ROUTES.HOME) {
    return;
  }

  const sessionId = getSessionId();

  // No monolith login tracking if there is no session id
  if (!sessionId) {
    return;
  }

  // No monolith login tracking if user didn't come from monolith
  if (!document.referrer || !(process.env.SURVEYJUNKIE_MONOLITH_MEMBER || '').includes(document.referrer)) {
    return;
  }

  const lastSessionId = getLocalStorage(LOCAL_STORAGE_IDS.LAST_SESSION_ID);

  if (lastSessionId === sessionId) {
    return;
  }

  // check session id and if it's different
  analyticsTrackLoginStart(ANALYTICS_EVENT_SUB_TYPE.login_page_monolith);

  // Timeout so that we ensure that the events will be sent in order
  setTimeout(() => {
    analyticsTrackLoginEnd(ANALYTICS_EVENT_SUB_TYPE.login_page_monolith);
  }, 500);
};

export {
  initLoginFromMonolithTracking
};