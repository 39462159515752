import { AnalyticsEvent, analyticsTrack } from "Shared/services/analytics/config";
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES } from "Shared/services/analytics/constants";

export interface PageViewEvent {
  [ANALYTICS_EVENT_ATTRIBUTES.page_title]    : string | undefined;
  [ANALYTICS_EVENT_ATTRIBUTES.page_location] : string | undefined;
  [ANALYTICS_EVENT_ATTRIBUTES.page_referrer] : string | undefined;
}

export const analyticsTrackPageView = (pageViewEvent: PageViewEvent): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.page_view,
    attributes: pageViewEvent
  };

  analyticsTrack([event]);
};