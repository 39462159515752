import { AnalyticsEvent, analyticsTrack } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
  ANALYTICS_RESPONSE_CODES,
  ANALYTICS_RESPONSE_MESSAGE,
} from 'Shared/services/analytics/constants';

/**
 * Triggered when a user completes auto login
 * @returns void
 */
export const analyticsTrackLoginEnd = (subType: ANALYTICS_EVENT_SUB_TYPE, errorCode?: string | number): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.end,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.type]            : ANALYTICS_EVENT_TYPE.login,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]        : subType,
      [ANALYTICS_EVENT_ATTRIBUTES.response_code]   : errorCode || ANALYTICS_RESPONSE_CODES.success,
      [ANALYTICS_EVENT_ATTRIBUTES.response_message]: errorCode ? ANALYTICS_RESPONSE_MESSAGE.error  : ANALYTICS_RESPONSE_MESSAGE.success,
    }
  };

  analyticsTrack([event]);
};