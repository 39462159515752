import { NavigateFunction, useNavigate } from 'react-router-dom';
import { MENU_ROUTES } from 'App/components/sidebar/constants';
import { MEMBER_FORGOT_PASSWORD_URL, MEMBER_SIGN_IN_URL, MEMBER_SIGN_UP_URL, PAGE_MEMBER, RED_CARPET_URL, REWARDS_PAGE, UNRECOGNIZED_DEVICE_URL } from 'App/routes/constants';
import { NOTIFICATION_CODE_QUERY_PARAM } from 'Pages/signIn/constants/queryParams';
import { isInMobileApp, MobileAppEvent, MobileAppPages, sendAppMessage } from 'Shared/services/mobileAppMessenger';
import { getOutgoingSurveyUrl } from 'Shared/services/surveyRouting/app/surveyRoutingService';

const navigateToMemberPage = (navigate?: NavigateFunction, appReferrer?: MobileAppPages): string | void => {
  if (isInMobileApp()) {
    sendAppMessage({
      event   : MobileAppEvent.navigate,
      page    : MobileAppPages.memberHome,
      referrer: appReferrer
    });

    return '';
  }

  if (navigate) {
    navigate(PAGE_MEMBER);
  } else {
    if (window.location.pathname === PAGE_MEMBER) {
      return window.location.href;
    }

    window.location.href = PAGE_MEMBER;
    return window.location.href;
  }
};

const navigateToRewardsPage = (navigate?: NavigateFunction, appReferrer?: MobileAppPages): string | void => {
  if (isInMobileApp()) {
    sendAppMessage({
      event   : MobileAppEvent.navigate,
      page    : MobileAppPages.rewards,
      referrer: appReferrer
    });

    return '';
  }

  if (navigate) {
    navigate(REWARDS_PAGE);
  } else {
    if (window.location.pathname === REWARDS_PAGE) {
      return window.location.href;
    }

    window.location.href = REWARDS_PAGE;
    return window.location.href;
  }
};

const navigateToContactUsPage = (appReferrer?: MobileAppPages): void => {
  if (isInMobileApp()) {
    sendAppMessage({
      event   : MobileAppEvent.navigate,
      page    : MobileAppPages.contactUs,
      referrer: appReferrer
    });

    return;
  }

  window.open(MENU_ROUTES.ACCOUNT_CONTACT_US);
};

const navigateToFaQPage = (): void => {
  window.open(MENU_ROUTES.ACCOUNT_SUPPORT);
};

const navigateToHelpPage = (): void => {
  window.open(MENU_ROUTES.ACCOUNT_SUPPORT);
};

const navigateToExternalSurvey = (surveyUrl: string, newWindow: boolean, appendClickData: boolean): void => {
  if (!surveyUrl) {
    return;
  }

  const outgoingUrl = getOutgoingSurveyUrl(surveyUrl, appendClickData);

  if (newWindow) {
    window.open(outgoingUrl, '_blank');
  } else {
    window.location.href = outgoingUrl;
  }
};

const useNavigateToSignUpPage = () => {
  const navigate = useNavigate();
  return () => navigate(MEMBER_SIGN_UP_URL);
};

const navigateToSignInPage = (errorCode?: number): void => {
  if (process.env.STORYBOOK_ENV === 'storybook') {
    return;
  }

  if (errorCode !== undefined) {
    window.location.href = `${MEMBER_SIGN_IN_URL}?${NOTIFICATION_CODE_QUERY_PARAM}=${errorCode}`;
    return;
  }

  window.location.href = MEMBER_SIGN_IN_URL;
};

const useNavigateToForgotPasswordPage = () => {
  const navigate = useNavigate();

  return (inputEmail?: string) => {
    if (inputEmail) {
      navigate(MEMBER_FORGOT_PASSWORD_URL, {
        state: {
          inputEmail: inputEmail
        }
      });
    } else {
      navigate(MEMBER_FORGOT_PASSWORD_URL);
    }
  };
};

const navigateToUnrecognizedDevicePage = (inputEmail: string, sendDeviceRequestAuth: string): void => {
  window.location.href = `${UNRECOGNIZED_DEVICE_URL}?email=${encodeURIComponent(inputEmail)}&auth_code=${sendDeviceRequestAuth}`;
};

/**
 * Redirect to pre-member site
 * @param page page to redirect to without leading slash
 * @returns
 */
const navigateToPreMember = (page = ''): void => {
  window.location.href = `${process.env.PRE_MEMBER_BASE_URL}/${page ?? ''}`;
};

const MF_EVENTS = {
  app             : {
    popup: {
      start: 'app-popup-start',
      exit : 'app-popup-exit'
    },
    autoemail: {
      start: 'auto-email-start'
    },
    onboarding: {
      start: 'ste-onboarding-start'
    },
    emailModal           : 'email-modal',
    questionnaireComplete: 'questionnaire-complete',
    stePreinstallModal   : 'ste-preinstall-modal'
  },
  rewards         : {
    giftCardRedemptionStart    : 'giftcard-redemption-start',
    bankTransferRedemptionStart: 'bank-transfer-redemption-start',
    refetchUserData            : 'refetch-user-data'
  },
  promotions: {
    optinStart: 'promo-optin-start'
  },
  surveyExperience: {
    removeSurvey            : 'remove-survey',
    removeUnansweredQuestion: 'remove-unanswered-question',
    surveyPrompt            : 'survey-prompt',
  },
  userActivation  : {
    questionnaireStart: 'user-activation-questionnaire-start'
  },
  loginModal      : {
    open: 'login-modal-open'
  },
  surveysLoading  : {
    action: 'surveys-loading-action',
  }
};

/**
 * Routes the user to the red carpet survey
 */
const navigateToRedCarpetSurvey = (): void => {
  window.open(`${RED_CARPET_URL}`, '_blank');
};

export {
  MF_EVENTS,
  navigateToContactUsPage,
  navigateToExternalSurvey,
  navigateToFaQPage,
  navigateToHelpPage,
  navigateToMemberPage,
  navigateToPreMember,
  navigateToRedCarpetSurvey,
  navigateToRewardsPage,
  navigateToSignInPage,
  navigateToUnrecognizedDevicePage,
  useNavigateToForgotPasswordPage,
  useNavigateToSignUpPage
};
