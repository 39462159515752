import { AnalyticsEvent, analyticsTrack } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
} from 'Shared/services/analytics/constants';

/**
 * Triggered when a user starts auto login
 * @returns void
 */
export const analyticsTrackLogoutStart = (autoLogout: boolean, responseCode?: number, eventSource?: string): void => {
  const subType = autoLogout ? ANALYTICS_EVENT_SUB_TYPE.logout_auto : ANALYTICS_EVENT_SUB_TYPE.logout_manual;

  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.start,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.type]         : ANALYTICS_EVENT_TYPE.logout,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]     : subType,
      [ANALYTICS_EVENT_ATTRIBUTES.source]       : eventSource,
      [ANALYTICS_EVENT_ATTRIBUTES.response_code]: responseCode || ''
    }
  };

  analyticsTrack([event]);
};
