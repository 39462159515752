import React, { createContext, useContext, useState } from 'react';
import { MOBILE_HEADER_HEIGHT } from 'Shared/styles/designConstants';

export const BannerHeight = createContext<[number, (val: number) => void]>([0, () => { /* noop */ }]);

export const BannerHeightProvider = ({ children }: { children: React.ReactNode }) => {
  const bannerHeightState = useState(0);

  return (
    <BannerHeight.Provider value={bannerHeightState}>
      {children}
    </BannerHeight.Provider>
  );
};

/**
 * A hook to get the current notification banner height, with `px` css units attached.
 * @returns The height in pixels, e.g. `'61px'`
 */
export const useBannerHeight = () => {
  const [bannerHeight] = useContext(BannerHeight);
  return `${bannerHeight}px`;
};

/**
 * A hook to get a css value for the remaining height available after subtracting the
 * notification banner, and possibly the mobile header.
 * @param includeMobileHeader Whether to account for the mobile header as well.
 * @returns The height in css units, e.g. `'calc(100vh - 61px)'`
 */
export const useFullHeight = (includeMobileHeader = false) => {
  const [bannerHeight] = useContext(BannerHeight);
  const mobileHeaderPart = includeMobileHeader ? ` - ${MOBILE_HEADER_HEIGHT}` : '';

  return `calc(100vh - ${bannerHeight}px${mobileHeaderPart})`;
};
