export const CONTAINER_ID = {
  APP: 'app-root'
};

export const IDP_API_CONFIG = '/v2/oauth';
export const IDP_TOKEN      = '/token';

export const COOKIE_IDS = {
  MONOLITH_LOGIN               : 'monolithLogin',
  IDP_ACCESS                   : 'idpat',
  IDP_REFRESH                  : 'idprt',
  USER_DEVICE                  : 'device',
  USER_ID                      : 'sjUserId',
  SEO_IMPACT_CLICK             : 'impactClickId',
  SEO_GCLID                    : 'gclid',
  SEO_FBCLID                   : 'fbclid',
  SEO_MSCLKID                  : 'msclkid',
  SEO_CRID                     : 'crid',
  SEO_AE_CLICK_TYPE_ID         : 'clickTypeId',
  ANALYTICS_DEBUG              : 'sjAnalyticsDebug',
  TFA_DEBUG_PHONE              : '2faDebugPhone',
  GA4_CLIENT_ID                : '_ga',
  FROM_V2                      : 'fromV2',
  PASSIVE_METERING_DATA        : 'pmdata',
  EXTENSION_APP_DOWNLOAD_TOKEN : 'extensionDownloadToken',
  DESKTOP_APP_DOWNLOAD_TOKEN   : 'desktopDownloadToken',
  IS_FIRST_VISIT_FROM_V2       : 'isFirstVisitFromV2',
  DEVICE_ID                    : 'deviceId',
  DEVICE                       : 'device',
  DEVICE_INFO                  : 'deviceInfo',
  FORCE_TO_V3_FOR_NEW_SIGNUP   : 'forceTov3ForNewSignups',
  BRAZE_SURVEY_LOAD_EVENT      : 'brazesle',
  BRAZE_REWARD_PAGE_VISIT      : 'brazerpv',
  BRAZE_REWARD_OPTION_CLICK    : 'brazeroc',
  BRAZE_GAMING_ENABLED_EVENT   : 'brazegee',
  USER_VISIT_DATE              : 'userVisitDate',
  DYNAMIC_SURVEY_MEDLEY_ENABLED: 'dynamicSurveyMedley',
  LEADERBOARD_TOOL_TIP_VIEWED  : 'lbtv',
  FINGERPRINT_ID               : 'fp',
  REWARDS_IDEMPOTENCY_KEY      : 'rewIdeKey',
  AUTO_GAMING_REDIRECT         : 'aGamingR',
} as const;

export const PERSISTED_COOKIE_IDS = [
  COOKIE_IDS.SEO_IMPACT_CLICK as string,
  COOKIE_IDS.SEO_GCLID as string,
  COOKIE_IDS.SEO_FBCLID as string,
  COOKIE_IDS.SEO_MSCLKID as string,
  COOKIE_IDS.SEO_CRID as string,
  COOKIE_IDS.ANALYTICS_DEBUG as string,
  COOKIE_IDS.GA4_CLIENT_ID as string,
  COOKIE_IDS.PASSIVE_METERING_DATA as string,
  COOKIE_IDS.EXTENSION_APP_DOWNLOAD_TOKEN as string,
  COOKIE_IDS.DESKTOP_APP_DOWNLOAD_TOKEN as string,
  COOKIE_IDS.DEVICE_ID as string,
  COOKIE_IDS.DEVICE as string,
  COOKIE_IDS.DEVICE_INFO as string,
  COOKIE_IDS.USER_DEVICE as string,
  COOKIE_IDS.USER_VISIT_DATE as string,
  COOKIE_IDS.LEADERBOARD_TOOL_TIP_VIEWED as string,
  COOKIE_IDS.BRAZE_SURVEY_LOAD_EVENT as string,
  COOKIE_IDS.BRAZE_REWARD_PAGE_VISIT as string,
  COOKIE_IDS.BRAZE_REWARD_OPTION_CLICK as string,
  COOKIE_IDS.BRAZE_GAMING_ENABLED_EVENT as string,
  COOKIE_IDS.AUTO_GAMING_REDIRECT as string,
];

export const COOKIE_EXPIRY_IN_DAYS = {
  AUTO_EMAIL: 0.5
};

/**
 * Set the passive metering data cookie expiry to 31 days
 */
export const PASSIVE_METERING_DATA_EXPIRY_IN_DAYS = {
  ACCESS : 31,
};

/**
 * Set the app download token cookie expiry to 7 days
 */
export const INSTALLATION_TOKEN_DATA_EXPIRY_IN_DAYS = {
  ACCESS : 7,
};

export const APP_ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  E2E        : 'e2e',
  TESTING    : 'testing',
  STAGING    : 'staging',
  PRODUCTION : 'production'
} as const;

export const AE_QUERY_PARAMS = {
  CATEGORY_ID: 'aeCId',
  ID         : 'aeId',
  SENT_TIME  : 'aeT'
} as const;
