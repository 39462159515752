/**
 * MEW-728
 * Purpose of this function is to convert boolean values to string values
 * because there's an issue with GA4 where it will show false but not show true
 * @param data
 * @returns
 */
const convertBooleansToString = (data: any) => {
  Object.keys(data).forEach((key: string) => {
    if (typeof data[key] === 'boolean') {
      data[key] = data[key] ? 'true' : 'false';
    }
  });

  return data;
};

export default convertBooleansToString;