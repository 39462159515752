import { logError } from 'App/services/coralogixService';
import {
  deleteLocalStorageFallback,
  getLocalStorageFallback,
  hasLocalStorageFallback,
  isLocalStorageAvailable,
  setLocalStorageFallback
} from 'Shared/services/localStorageFallbackService';
import { LocalStorageId, LocalStorageTypes, MappedLocalStorageId, UnmappedLocalStorageId } from "Shared/services/localStorageTypes";

function hasLocalStorage(key: string) {
  if (!isLocalStorageAvailable) {
    return hasLocalStorageFallback(key);
  }

  return !!window.localStorage.getItem(key);
}

/**
 * Gets local storage item according to input string or object
 * @param key
 * @param parseJSON
 */
function getLocalStorage<TKey extends MappedLocalStorageId> (key: TKey, parseJSON: true): LocalStorageTypes[TKey] | null
function getLocalStorage<TResponse = any> (key: UnmappedLocalStorageId, parseJSON: true): TResponse | null
function getLocalStorage (key: UnmappedLocalStorageId, parseJSON?: false): string
function getLocalStorage (key: LocalStorageId, parseJSON = false): any {
  try {
    if (!isLocalStorageAvailable) {
      return getLocalStorageFallback(key, parseJSON);
    }

    const localStorageData = window.localStorage.getItem(key) || '';

    if (parseJSON) {
      if (localStorageData) {
        return JSON.parse(localStorageData);
      } else {
        return null;
      }
    } else {
      return localStorageData;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Error retrieving ${ key } from local storage`, error);
    logError('getLocalStorage', error);
  }

  return null;
}

/**
 * Sets item in local storage
 * @param key - local storage name
 * @param value - local storage value to store
 */
function setLocalStorage<TKey extends MappedLocalStorageId> (key: TKey, value: LocalStorageTypes[TKey], stringifyJSON: true): void
function setLocalStorage (key: UnmappedLocalStorageId, value: any, stringifyJSON: true): void
function setLocalStorage (key: UnmappedLocalStorageId, value: string | null | undefined, stringifyJSON?: false): void
function setLocalStorage (key: LocalStorageId, value: string | any, stringifyJSON = false): void {
  try {
    if (!isLocalStorageAvailable) {
      return setLocalStorageFallback(key, value, stringifyJSON);
    }

    if (stringifyJSON) {
      return window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      return window.localStorage.setItem(key, value);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Error setting ${ key } to local storage`, error);
    logError('setLocalStorage', error);
  }
}

/**
 * Deletes an item in local storage
 * @param key
 */
const deleteLocalStorage = (key: LocalStorageId): void => {
  try {
    if (!isLocalStorageAvailable) {
      deleteLocalStorageFallback(key);
      return;
    }

    return window.localStorage.removeItem(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Error removing ${ key } from local storage`);
    logError('deleteLocalStorage', error);
  }
};

export {
  deleteLocalStorage,
  getLocalStorage,
  hasLocalStorage,
  setLocalStorage
};
