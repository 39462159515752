const ONE_HOUR_COOKIE_TTL = 1/24;

/**
 * Gets cookies from storage according to input string
 * @param cname
 */
const getCookie = (cname: string): string => {
  const name = cname + '=';
  const ca   = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length,c.length);
    }
  }

  return '';
};

/**
 * Sets cookie in storage
 * @param cname - cookie name
 * @param cvalue - cookie value to store
 * @param exdays - time in days to store cookie
 * @param domain - determines it should be set to the ";domain=surveyjunkie{-env}.uk" domain
 */
const setCookie = (cname: string, cvalue: string | number, exdays: number, domain?: boolean): void => {
  const d = new Date();
  d.setTime(d.getTime()+(exdays*24*60*60*1000));
  const expires = 'expires='+d.toUTCString();
  let cookie    = cname+'='+cvalue+'; '+expires+';path=/;SameSite=Lax';

  if (domain && process.env.COOKIE_DOMAIN !== 'localhost') {
    cookie = cookie + `;domain=.${process.env.COOKIE_DOMAIN}`;
  }

  document.cookie = cookie;
};

/**
 * Deletes a cookie in storage
 * @param cname
 * @param includeDomainRemoval - set it to true for deleting cookie at domain level
 */
const deleteCookie = (cname: string, includeDomainRemoval = false): void => {
  const d = new Date();
  d.setTime(d.getTime() - (1000*60*60*24));
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname+"="+"; " + expires + ";path=/;";

  if (includeDomainRemoval) {
    const domainPath = window.location.hostname.split('.');
    domainPath.shift();
    const cookieDomain = `.${domainPath.join('.')}`;
    document.cookie = cname+"="+"; " + expires + ";path=/;domain=" + cookieDomain + ";";
  }
};

export {
  deleteCookie,
  getCookie,
  ONE_HOUR_COOKIE_TTL,
  setCookie
};
