interface ListenerResponse {
  remove: () => void;
}

/**
 * Event Listener setup for window.addEventListener
 * @param eventName - event name from window.mfEvents
 * @param callback - input generic T for input props
 */
export const addEventListener = <T>(eventName: string, callback: (t: T) => void): ListenerResponse => {
  const listener = (event: CustomEvent<T>) => {
    const detail: T = event.detail;
    callback(detail);
  };
  
  window.addEventListener(eventName, listener as EventListener);

  return {
    remove: () => window.removeEventListener(eventName, listener as EventListener)
  };
};

/**
 * Remove event listener from global window
 * @param eventName - event name from window.mfEvents
 * @param callback - input generic T for input props
 */
export const removeEventListener = <T>(eventName: string, callback: (t: T) => void): void => {
  window.removeEventListener(eventName, ((event: CustomEvent<T>) => {
    const detail: T = event.detail;
    callback(detail);
  }) as EventListener);
};

/**
 * Dispatch Event Listener setup for window.dispatchEvent
 * @param eventName - event name from window.mfEvents
 * @param eventProps - options props
 */
export const dispatchEvent = <T>(eventName: string, eventProps?: T): void => {
  if (eventProps !== undefined) {
    window.dispatchEvent(
      new CustomEvent(eventName, {
        detail: eventProps
      })
    );
  } else {
    window.dispatchEvent(
      new CustomEvent(eventName)
    );
  }
};