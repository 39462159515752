import { IDP_API_CONFIG, IDP_TOKEN } from 'App/constants';
import { logError } from 'App/services/coralogixService';
import { getAccessTokenCookie, getAccessTokenPayload, getRefreshTokenPayload, logUserOut, renewAccessToken, renewAccessTokenPreemptively } from 'App/services/idpTokenService';
import axios, { type AxiosResponse,AxiosError, AxiosHeaders, AxiosRequestConfig } from 'axios';
import { dispatchLoginModalOpenEvent } from 'Shared/components/design/loginModal/routes/dispatchEvents';
import { waitForLogin } from 'Shared/components/design/loginModal/routes/waitForLogin';
import { APP_ID } from 'Shared/constants';
import { FULL_SCOPE, LIMITED_SCOPE } from 'Shared/constants/token';
import { ANALYTICS_EVENT_SOURCE } from 'Shared/services/analytics/constants';
import { MONOLITH_LOGIN_ENDPOINT } from 'Shared/services/monolith/constants';
import { navigateToUnrecognizedDevicePage } from 'Shared/services/routingService';
import { IDP_ERROR_CODE_PASSWORD_EXPIRED, IDP_ERROR_UNKNOWN_DEVICE_ID } from 'Shared/services/userActivation/app/idpService';

const MSInstance  = axios.create({});

type ValidationMethod = {
  method : string;
  baseURL: string;
  url    : string;
}

const NO_REFRESH_REQUESTS: ValidationMethod[] = [
  {
    method : '*',
    baseURL: process.env.REACT_APP_MONOLITH_BASE_URL || '',
    url    : MONOLITH_LOGIN_ENDPOINT
  },{
    method : '*',
    baseURL: process.env.REACT_APP_OPA_BASE_URL || '',
    url    : '*'
  },{
    method : '*',
    baseURL: process.env.REACT_APP_OPA_BASE_URL || '',
    url    : '*'
  }
];

function shouldNotReAuth (request: ValidationMethod) {
  return NO_REFRESH_REQUESTS.some((allow) => {
    const allowMethod    = (allow.method    || '').toLowerCase();
    const requestMethod  = (request.method  || '').toLowerCase();
    const allowBaseUrl   = (allow.baseURL   || '').toLowerCase();
    const requestBaseUrl = (request.baseURL || '').toLowerCase();
    const allowUrlPath   = (allow.url       || '').toLowerCase();
    const requestUrlPath = (request.url     || '').toLowerCase();

    if (allowBaseUrl !== requestBaseUrl) {
      return false;
    }

    if (allowMethod !== '*' && allowMethod !== requestMethod) {
      return false;
    }

    if (allowUrlPath !== '*' && allowUrlPath !== requestUrlPath) {
      return false;
    }

    return true;
  });
}

const getIsLimitedScopeToken = (): boolean => {
  const token = getAccessTokenPayload(true) ?? getRefreshTokenPayload(true);

  if (!token) {
    return false;
  }

  return token ? token.scp.includes(LIMITED_SCOPE) && !token.scp.includes(FULL_SCOPE)
    : false;
};

const getIsFullScopeToken = (): boolean => {
  const token = getAccessTokenPayload(true) ?? getRefreshTokenPayload(true);

  if (!token) {
    return false;
  }

  return token ? !token.scp.includes(LIMITED_SCOPE) && token.scp.includes(FULL_SCOPE)
    : false;
};

/**
 * Returns a service
 */
const CacheService = (() => {
  interface CacheConfiguration {
    url: string;
    ttl: number;
  }

  // Each entry is a configuration for a URL
  const cacheConfigurations: CacheConfiguration[] = [{
    url: `v1/apps/${ APP_ID }/questions`,
    ttl: 1000 * 60 * 60 // one hour in milliseconds
  }];

  // Map the cache configuration to a list of urls
  // so that it is quicker/easier to check if a url
  // is cacheable.
  const cacheableUrls: string[] = cacheConfigurations.map(configuration => configuration.url);
  const cacheStore: any         = {};

  /**
   * Returns the unique request signature used to store and retrieve from cache
   * @param url
   * @param params
   * @returns string | null
   */
  const getRequestSignature = (url: string | undefined, params?: object | undefined): string | null => {
    if (!url) {
      return null;
    }

    try {
      const paramObject = JSON.stringify(params || {});
      return `${ url }|${ paramObject }`;
    } catch (error) {
      logError('getRequestSignature', error);
      return null;
    }
  };

  return {
    /**
     * Checks whether the method and url is cacheable
     * @param method
     * @param url
     * @returns boolean
     */
    isCacheable: (method: string | undefined, url: string | undefined): boolean => {
      if (method !== 'get' && url) {
        return false;
      }

      return cacheableUrls.includes(url + '');
    },
    /**
     * Checks whether we have data in the cache
     * @param url
     * @param params
     * @returns boolean
     */
    hasCache: (url: string | undefined, params?: object | undefined): boolean => {
      const requestSignature: string | null = getRequestSignature(url, params);

      if (!requestSignature) {
        return false;
      }

      if (!cacheStore[requestSignature]) {
        return false;
      }

      const cacheConfiguration: CacheConfiguration = cacheConfigurations.filter(config => config.url === url)[0];

      if (!cacheConfiguration) {
        return false;
      }

      const cacheExpirationTime: number = cacheStore[requestSignature].createdAt + cacheConfiguration.ttl;

      // If the cache expiration timestamp is greater than
      // the current timestamp then the cache isn't expired.
      return Date.now() < cacheExpirationTime;
    },
    /**
     * Stores the response data in the cache store
     * @param response
     * @param url
     * @param params
     * @returns void
     */
    set: (response: AxiosResponse, url: string | undefined, params?: object | undefined): void => {
      const requestSignature: string | null = getRequestSignature(url, params);

      if (!requestSignature) {
        return;
      }

      cacheStore[requestSignature] = {
        response : response,
        createdAt: Date.now()
      };
    },
    /**
     * Returns the cache if it is available
     * @param url
     * @param params
     * @returns
     */
    get: (url: string | undefined, params?: object | undefined): any => {
      const requestSignature: string | null = getRequestSignature(url, params);

      if (!requestSignature) {
        return;
      }

      return cacheStore[requestSignature].response;
    }
  };
})();

/**
 * Create REQUEST interceptor to add access token for all MS calls
 */
MSInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  return new Promise((resolve) => {
    // Attempt to renew access token to prevent
    // expiration mid session.
    renewAccessTokenPreemptively();
    const headers = new AxiosHeaders(config.headers as AxiosHeaders);

    if (CacheService.isCacheable(config.method, config.url) && CacheService.hasCache(config.url, config.params)) {
      config.adapter = () => {
        return new Promise((resolve) => {
          return resolve(CacheService.get(config.url, config.params));
        });
      };

      resolve({
        ...config,
        headers
      });
      return;
    }

    headers.set('Authorization', `Bearer ${getAccessTokenCookie()}`);
    resolve({
      ...config,
      headers
    });
  });
});

/**
 * Create RESPONSE interceptor to add access token retry logic for all 401 responses
 */
MSInstance.interceptors.response.use((response: AxiosResponse) => {
  const config: AxiosRequestConfig = response.config;

  if (CacheService.isCacheable(config.method, config.url)) {
    CacheService.set(response, config.url, config.params);
  }

  return response;
}, (error) => {
  const originalRequest     = error.config;
  const authTokenURL        = `${process.env.REACT_APP_IDP_BASE_URL}${IDP_API_CONFIG}${IDP_TOKEN}`;
  const status              = error?.response?.status;
  const token               = getAccessTokenPayload();
  const limitedScopeToken   = getIsLimitedScopeToken();

  if (shouldNotReAuth(originalRequest)) {
    return Promise.reject(error);
  }

  if (limitedScopeToken && (status === undefined || status === 401 || status === 403)) {
    dispatchLoginModalOpenEvent({ open: true });
    return waitForLogin().then(() => {
      return MSInstance(originalRequest);
    });
  }

  if (status !== 401) {
    return Promise.reject(error);
  }

  if (error.response?.data?.errorCodes?.includes(IDP_ERROR_UNKNOWN_DEVICE_ID)) {
    const authCode = error.response.data.sendDeviceRequestAuth;

    let userEmail;

    if (token) {
      userEmail = token.sub;
    } else if (originalRequest) {
      userEmail = originalRequest.data.username;
    } else {
      userEmail = '';
    }

    if (!userEmail || !authCode) {
      logUserOut(true, undefined, undefined, ANALYTICS_EVENT_SOURCE.missing_auth);
    }

    navigateToUnrecognizedDevicePage(userEmail, authCode);
    return Promise.reject(error);
  }

  // If error is a 401 error, attempt retry logic
  if (originalRequest.url === authTokenURL) {
    const code = error.response?.data?.errorCodes?.[0];
    if (code === IDP_ERROR_CODE_PASSWORD_EXPIRED) {
      logUserOut(true, code, undefined, ANALYTICS_EVENT_SOURCE.password_expired);
      return Promise.reject(error);
    }

    logUserOut(true, false, code, ANALYTICS_EVENT_SOURCE.access_forbidden);
    return Promise.reject(error);
  }

  // Only retry the original request once
  if (!originalRequest._retry) {
    originalRequest._retry   = true;

    return renewAccessToken().then(() => {
      return MSInstance(originalRequest);
    }).catch((error: any) => {
      logError('renewAccessToken', error);
      return Promise.reject(error);
    });
  }

  return Promise.reject(error);
});

export {
  type AxiosResponse as APIResponse,
  axios as API, // Used for all API calls that do NOT require IDP access token
  AxiosError as APIError,
  getIsFullScopeToken,
  getIsLimitedScopeToken,
  MSInstance as MS_API, // Used for all MS API calls that require the IDP access token
};
