/**
 * Cleans the empty values in the object
 * and returns it back
 * @returns object
 */
 const cleanEmptyFields = (data: any) => {
  Object.keys(data).forEach((key: string) => {
    if (data[key] === '' || typeof data[key] === 'undefined') {
      delete data[key];
    }
  });

  return data;
};

export default cleanEmptyFields;