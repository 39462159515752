const LOGIN_EVENT = 'login';

class LoginTarget extends EventTarget {}

const loginTarget = new LoginTarget();

export function waitForLogin () {
  return new Promise<void>((resolve) => {
    loginTarget.addEventListener(LOGIN_EVENT, () => resolve(), { once: true });
  });
}

export function onLogin () {
  loginTarget.dispatchEvent(new CustomEvent(LOGIN_EVENT));
}
