import { COOKIE_IDS } from 'App/constants';
import { getWurfl } from 'App/services/wurflService';
import { WURFL_BROWSERS } from 'Shared/constants';
import { setCookie } from 'Shared/services/cookieService';

enum UspDeviceType {
  Desktop    = 'DESKTOP',
  Smartphone = 'SMARTPHONE',
  Tablet     = 'TABLET',
  android    = 'PHONE_ANDROID',
  ios        = 'PHONE_IOS'
}

interface DeviceScreenDimensions {
  height: number,
  width: number
}

/**
 * Returns if the device is mobile or not based on user agent and `sjViewMode` cookie
 */
const isMobile = (): boolean => {
  return getWurfl().is_mobile;
};


/**
 * Returns the user's device name. If wurfl doesn't load, default to 'Smartphone' or 'Desktop'
 */
const getFormFactor = (): string => {
  if (getWurfl().form_factor !== '') {
    return getWurfl().form_factor;
  }

  return isMobile() ? 'Smartphone' : 'Desktop';
};

/**
 * Checks if user's device is a windows 10 desktop
 */
const isWindows10Desktop = ():boolean => {
  return window.navigator.userAgent.indexOf('Windows NT 10') != -1 && !isMobile();
};

/**
 * Checks if device is an iOS device, per WURFL data
 */
const isIosDevice = (): boolean => {
  const lowercaseWurfl = getWurfl().advertised_device_os.toLowerCase();
  return lowercaseWurfl === 'ios';
};

/**
 * Checks if device is an android device per WURFL data
 */
const isAndroidDevice = (): boolean => {
  const lowercaseWurfl = getWurfl().advertised_device_os.toLowerCase();
  return lowercaseWurfl === 'android';
};

/**
 * Detects if user's browser is Safari
 */
const isSafariBrowser = ():boolean => {
  return getWurfl().advertised_browser === WURFL_BROWSERS.safari;
};

/**
 * Gets the DeviceType enum for the USP MS
 */
const getUspDevice = (): UspDeviceType => {
  const { form_factor, is_mobile } = getWurfl();

  switch (form_factor) {
    case 'Smartphone':
      return UspDeviceType.Smartphone;
    case 'Tablet':
      return UspDeviceType.Tablet;
    case 'Desktop':
      return UspDeviceType.Desktop;
  }

  if (is_mobile) {
    return UspDeviceType.Smartphone;
  }

  return UspDeviceType.Desktop;
};

/**
 * Gets the device paramter for requesting surveys
 */
const getSurveyDevice = (): string => {
  const wurfl = getWurfl();
  return JSON.stringify({
    is_mobile           : isMobile(),
    complete_device_name: wurfl.complete_device_name,
    form_factor         : wurfl.form_factor,
  });
};

/**
 * Set the device cookie
 */
const initDeviceCookie = (): void => {
  const wurflData = getWurfl();
  setCookie(COOKIE_IDS.DEVICE, JSON.stringify(wurflData), 365, true);

  //setting device info cookie to replicate monolith (V2) logic
  setCookie(COOKIE_IDS.DEVICE_INFO, JSON.stringify({
    dimensions: {
      h: window.screen.height,
      w: window.screen.width
    },
    hasFlash: false
  }),365, true);
};

/**
 * Gets the device screen dimensions
 */
const getDeviceScreenDimensions = (): DeviceScreenDimensions | undefined => {
  if (typeof window !== 'undefined' && window.screen) {
    return {
      height: window.screen.height,
      width : window.screen.width
    };
  }
};

export {
  getDeviceScreenDimensions,
  getFormFactor,
  getSurveyDevice,
  getUspDevice,
  initDeviceCookie,
  isAndroidDevice,
  isIosDevice,
  isMobile,
  isSafariBrowser,
  isWindows10Desktop,
  UspDeviceType
};
