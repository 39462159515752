export const ACCOUNT_SUPPORT_MOBILE_SIDE_NAV_OPEN = '?account-view';

export const MENU_ROUTES = {
  HOME                                : '/',
  REWARDS                             : '/rewards',
  ACCOUNT_HOME                        : '/help',
  ACCOUNT_PROFILE                     : '/help/account',
  ACCOUNT_CONTACT_US                  : '/help/contact-us',
  ACCOUNT_SUPPORT                     : '/help/support',
  ACCOUNT_SUPPORT_MOBILE_SIDE_NAV_OPEN: `/help/support${ACCOUNT_SUPPORT_MOBILE_SIDE_NAV_OPEN}`,
  ACCOUNT_TERMS                       : '/help/terms',
  ACCOUNT_PRIVACY                     : '/help/privacy',
  ACCOUNT_PERSONAL_INFO               : '/help/personal-info',
  ACCOUNT_PERSONAL_INFO_EDIT_NAME     : '/help/personal-info/edit-name',
  ACCOUNT_PERSONAL_INFO_EDIT_CODE     : '/help/personal-info/edit-code',
  ACCOUNT_PERMISSIONS                 : '/help/permissions',
  ACCOUNT_PROFILE_QUESTIONS           : '/help/profile-questions',
  ACCOUNT_INVITE                      : '/help/invite',
  ACCOUNT_INVITE_TERMS                : '/help/invite-terms',
  ACCOUNT_LEADERBOARD                 : '/help/leaderboard',
  HOW_ONFIDO_WORKS                    : '/how-onfido-works',
  REVIEW_ONFIDO_PERSONAL_INFO         : '/onfido-review-personal-info',
  GAMES                               : '/games'
} as const;

export const REWARDS_ROUTE = '/rewards';
