import { ANALYTICS_EVENT_NAMES } from "Shared/services/analytics/constants";


/**
 * Accepts analytics events to be passed to GTM
 * @params eventType - list of events to be sent to GTM dataLayer
 * @param eventPayload {object} - event attribute payload
 * @returns void
 */
export function initGtmDatalayerEvents(eventType: ANALYTICS_EVENT_NAMES, eventPayload: object) {
  if (window.dataLayer != undefined) {
    window.dataLayer.push({
      'event': eventType,
      ...eventPayload
    });
  }
}

export function setGtmUserProperties(userProps: any) {
  if (window.dataLayer != undefined) {
    window.dataLayer.push(function (this: any) {
      this.set(ANALYTICS_EVENT_NAMES.user_properties, userProps);
    });
  }
}