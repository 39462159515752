export const IDP_API_CONFIG          = '/v2/oauth';
export const IDP_TOKEN               = '/token';
export const IDP_USERS               = '/v1/users';
export const IDP_UPDATE_PASSWORD     = '/update-password';
export const IDP_RESET_PASSWORD      = '/send-reset-password';
export const IDP_EXCHANGE_HASH_TOKEN = '/exchange-hash-token';
export const IDP_SEND_DEVICE_REQUEST = '/send-device-request';
export const IDP_VERIFY_DEVICE       = '/verify-device';
export const IDP_SEND_CONFIRM_EMAIL  = '/send-confirm-email';

export const APP_SIGN_IN_DEEP_LINK = 'surveyjunkie://com.surveyjunkie/sign-in';

type UPM_CATEGORY_IDS = {
  BASIC_PROFILE  : number;
  GENERAL_PROFILE: number;
  SURVEY_MEDLEY  : number;
}

const UPM_CATEGORY_IDS_US: UPM_CATEGORY_IDS =  {
  BASIC_PROFILE  : 1,
  GENERAL_PROFILE: 2,
  SURVEY_MEDLEY  : 24
};

export const getUpmCategoryIds = (): UPM_CATEGORY_IDS => {
  return UPM_CATEGORY_IDS_US;
};

export const CUSTOM_QUESTION_KEYS = {
  CHILDREN: 'ChildGenderAge',
};

export const MAX_NUMBER_OF_CHILDREN = 8;

export const NUMBER_OF_CHILDREN_QUESTION_KEY = 'Children';

export const CHILD_QUESTION_KEY_PREFIX = 'Child_';

export const USER_ID_EXPIRY_IN_DAYS = 365;

export const MINIMUM_AGE = 18;

export const MINIMUM_AGE_IDP_STATUS = "INACTIVE";

export const MINIMUM_NAME_LENGTH = 1; // Names must be more than this.

const getBasicProfileQuestionsIds = (): number[] => {
  // DoB, Zipcode
  return [1, 7];
};

export const BASIC_PROFILE_QUESTION_IDS        = getBasicProfileQuestionsIds();

export const BASIC_PROFILE_COMPLETION_REWARD = 25;

export const ANSWER_SOURCE_DIRECT = 'DIRECT';

export const IDP_ERROR_BAD_POST_CODE              = 43;
export const IDP_ERROR_PASSWORD_FAILED_VALIDATION = 52;
export const IDP_ERROR_FIRST_NAME_CANNOT_BE_EMPTY = 18;
export const IDP_ERROR_LAST_NAME_CANNOT_BE_EMPTY  = 19;
export const IDP_ERROR_INVALID_DATE_TIME_VALUE    = 32;

export const HELP_PAGE_TEST_ID = "help-page-test-id";
