import { logError } from 'App/services/coralogixService';
import { getRFGFingerprint } from 'Shared/services/rfgService';


/**
 * Checks if it's a monolith survey by checking if
 * the url has "/member/go"
 * @returns {boolean}
 */
const isMonolithSurveyUrl = (surveyUrl: string): boolean => {
  surveyUrl = surveyUrl || '';
  return surveyUrl.includes('/member/go');
};

/**
 * Returns the survey click data
 * @returns string
 */
const getSurveyClickData = (isAutoEmail: boolean): string => {
  const clickData = {
    impression_position  : isAutoEmail ? null : 1,
    impression_column    : isAutoEmail ? null : 1,
    impression_row       : isAutoEmail ? null : 1,
    device_display_width : window.screen.width,
    device_display_height: window.screen.height,
    fp                   : Date.now(),
    fp_rfg               : getRFGFingerprint(),
  };

  // Prepare ClickData for sending
  let encodedClickData = btoa(JSON.stringify(clickData));
  // '+' replaced with '-'
  encodedClickData = encodedClickData.replace(/\+/g,'-');
  // '/' replaced with '_'
  encodedClickData = encodedClickData.replace(/\//g,'_');
  // any trailing '=' * characters are removed
  encodedClickData = encodedClickData.replace(/=+$/, '');

  return encodedClickData;
};

/**
 * Appends the clientAppId to survey URL
 * @param url - survey url
 * @returns string
 */
const getOutgoingSurveyUrl = (url: string, appendClickData = false, isAutoEmail = false): string => {
  let outgoingUrl = `${url || ''}`;

  try {
    const clientAppIdQueryParamKey = '&clientAppId=';
    if (!outgoingUrl.includes(clientAppIdQueryParamKey)) {
      outgoingUrl = `${outgoingUrl}${clientAppIdQueryParamKey}${process.env.USP_MRS_CLIENT_APP_ID}`;
    }
  } catch (error) {
    logError('getOutgoingSurveyUrl', error);
  }

  try {
    const clickDataQueryParamKey = '&cd=';
    if (appendClickData && isMonolithSurveyUrl(outgoingUrl) && !outgoingUrl.includes(clickDataQueryParamKey)) {
      outgoingUrl = `${outgoingUrl}${clickDataQueryParamKey}${getSurveyClickData(isAutoEmail)}`;
    }
  } catch (error) {
    logError('getOutgoingSurveyUrl', error);
  }

  return outgoingUrl;
};

export {
  getOutgoingSurveyUrl
};
