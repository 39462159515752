const TEST_KEY   = 'test_key';
const TEST_VALUE = 'test_value';

const _localStorage = new Map<string, string>();

const isLocalStorageAvailable = (() => {
  try {
    if (!window.localStorage) {
      return false;
    }

    window.localStorage.setItem(TEST_KEY, TEST_VALUE);

    if (window.localStorage.getItem(TEST_KEY) !== TEST_VALUE) {
      return false;
    }

    window.localStorage.removeItem(TEST_KEY);
    return true;
  } catch (e) {
    return false;
  }
})();

/**
 * Gets local storage item according to input string or object in the fallback mechanism
 * @param key the local storage key to look up
 * @param parseJSON whether to treat the data as a JSON string or as a plain string
 */
function getLocalStorageFallback (key: string, parseJSON = false) {
  const localStorageFallbackData = _localStorage.has(key) && _localStorage.get(key);

  if (parseJSON) {
    if (localStorageFallbackData) {
      return JSON.parse(localStorageFallbackData);
    } else {
      return null;
    }
  } else {
    return localStorageFallbackData;
  }
}

/**
 * Sets item in local storage fallback
 * @param key the local storage key to write to
 * @param value the value to write (possibly stringified)
 * @param stringifyJSON whether to stringify the incoming value before storing it in the fallback
 */
function setLocalStorageFallback (key: string, value: string | any, stringifyJSON = false): void {
  if (stringifyJSON) {
    _localStorage.set(String(key), JSON.stringify(value));
  } else {
    _localStorage.set(String(key), value);
  }
}

/**
 * Returns whether a local storage item exists in the fallback mechanism
 * @param key the local storage key to check for the existence of
 */
function hasLocalStorageFallback (key: string): boolean {
  return _localStorage.has(key);
}

/**
 * Deletes item from local storage fallback mechanism
 * @param key the local storage key to delete
 */
const deleteLocalStorageFallback = (key: string) => {
  return _localStorage.delete(key);
};

// Make _localStorage available at the window level
window._localStorage = _localStorage;

export {
  deleteLocalStorageFallback,
  getLocalStorageFallback,
  hasLocalStorageFallback,
  isLocalStorageAvailable,
  setLocalStorageFallback,
};
