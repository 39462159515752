import { logError } from 'App/services/coralogixService';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { APIResponse } from 'Shared/services/apiInterface';
import { getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';
import {
  createUser,
  getAuthTokensUsingCredentials,
  resendEmailConfirmationRequest
} from 'Shared/services/userActivation/api/idpApiService';

export const IDP_ERROR_UNKNOWN_DEVICE_ID                  = 107;
export const IDP_ERROR_CODE_PASSWORD_EXPIRED              = 109;
export const IDP_ERROR_CODE_GENERIC                       = -1;

/**
 * Creates a new user at signup
 * @param firstName
 * @param lastName
 * @param email
 * @param password
 * @param marketingOptIn
 * @param impactClickId
 * @param gclid
 * @param fbclid
 * @param msclkid
 */
const createNewUser = (email: string, password: string, marketingOptIn =true): Promise<APIResponse | null> => {
  // Create user in IDP. Retrieve user ID
  return new Promise((resolve, reject) => {
    createUser(email, password, marketingOptIn)
      .then(() => {
        return getAuthTokensUsingCredentials(email, password);
      })
      .then(() => {
        resolve(null);
      })
      .catch((error) => {
        logError('createUser', error);
        reject(error);
      });
  });
};

/**
 * Resends email confirmation
 * @returns
 */
const resendEmailConfirmation = (): void => {
  const MAX_SUBMIT_ATTEMPTS_IN_MINUTES = 1;
  const MILLISECONDS_IN_A_MINUTE       = 60000;
  const lastRequestData                = getLocalStorage(LOCAL_STORAGE_IDS.EMAIL_CONFIRMATION_REQUEST, true);

  if (!lastRequestData) {
    setLocalStorage(LOCAL_STORAGE_IDS.EMAIL_CONFIRMATION_REQUEST, {
      timestamp: Date.now(),
      attempts: 1
    }, true);
  } else {
    const { timestamp, attempts } = lastRequestData;
    const currentTime = Date.now();
    const timeDiffInMinutes = (currentTime - timestamp) / MILLISECONDS_IN_A_MINUTE;

    if (timeDiffInMinutes < MAX_SUBMIT_ATTEMPTS_IN_MINUTES) {
      // Too many requests
      return;
    } else {
      setLocalStorage(LOCAL_STORAGE_IDS.EMAIL_CONFIRMATION_REQUEST, {
        timestamp: currentTime,
        attempts: attempts + 1
      }, true);
    }
  }

  resendEmailConfirmationRequest()
    .then(() => {
      // Handle success if needed
    })
    .catch();
};

export {
  createNewUser,
  resendEmailConfirmation
};
