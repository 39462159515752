import { DESIGN_TOKENS } from "@disqotech/vinyl-design-system/design-tokens";
import { isInMobileApp } from "Shared/services/mobileAppMessenger";
import {css} from 'styled-components';

export const MEDIA_SIZES_IN_PIXELS = {
  maxSmallMobile       : 340,
  minMobile            : 360,
  minTwoColumnSurveys  : 758,
  maxMobile            : 900,
  maxTablet            : 952,
  maxDesktop           : 1439,
  maxThreeColumnSurveys: 1589,
  minUltraDesktop      : 1440,
  minThirdRail         : 1190
};

export const MEDIA_SIZES: Record<keyof typeof MEDIA_SIZES_IN_PIXELS, string> = {
  maxSmallMobile       : `${MEDIA_SIZES_IN_PIXELS.maxSmallMobile}px`,
  minMobile            : `${MEDIA_SIZES_IN_PIXELS.minMobile}px`,
  maxMobile            : `${MEDIA_SIZES_IN_PIXELS.maxMobile}px`,
  maxTablet            : `${MEDIA_SIZES_IN_PIXELS.maxTablet}px`,
  maxDesktop           : `${MEDIA_SIZES_IN_PIXELS.maxDesktop}px`,
  minUltraDesktop      : `${MEDIA_SIZES_IN_PIXELS.minUltraDesktop}px`,
  minThirdRail         : `${MEDIA_SIZES_IN_PIXELS.minThirdRail}px`,
  minTwoColumnSurveys  : `${MEDIA_SIZES_IN_PIXELS.minTwoColumnSurveys}px`,
  maxThreeColumnSurveys: `${MEDIA_SIZES_IN_PIXELS.maxThreeColumnSurveys}px`
};

export const DEVICE_BREAKPOINT = {
  maxSmallMobile : `(max-width: ${MEDIA_SIZES.maxSmallMobile})`,
  minMobile      : `(max-width: ${MEDIA_SIZES.minMobile})`,
  maxMobile      : `(max-width: ${MEDIA_SIZES.maxMobile})`,
  maxTablet      : `(max-width: ${MEDIA_SIZES.maxTablet})`,
  maxDesktop     : `(max-width: ${MEDIA_SIZES.maxDesktop})`,
  minUltraDesktop: `(max-width: ${MEDIA_SIZES.minUltraDesktop})`,
  minThirdRail   : `(max-width: ${MEDIA_SIZES.minThirdRail})`,
  minTwoColumn   : `(max-width: ${MEDIA_SIZES.minTwoColumnSurveys})`,
  maxThreeColumn : `(max-width: ${MEDIA_SIZES.maxThreeColumnSurveys})`
};

export const MOBILE_HEADER_HEIGHT = isInMobileApp() ? '0' : '51px';
export const Z_INDEX_OVER_FRESHCHAT = 2147483601;
export const QUESTIONNAIRE_CONTENT_MAX_WIDTH = '552px';

export const POPUP_BACKGROUND = css`
  position        : fixed;
  overflow        : auto;
  z-index         : 101;
  bottom          : 0;
  right           : 0;
  left            : 0;
  top             : 0;
  background-color: ${DESIGN_TOKENS.color.background.primary.value};
`;

export const ONFIDO_MODAL = css`
  position      : fixed;
  top           : 0;
  left          : 0;
  right         : 0;
  bottom        : 0;
  display       : flex;
  flex-direction: column;
  align-items   : center;
  background    : ${DESIGN_TOKENS.color.background.secondary.value};
  z-index       : 200;
  overflow      : auto;
`;

export const POPUP_HEADER_CONTAINER = css`
  width         : 100%;
  height        : 64px;
  box-shadow    : ${DESIGN_TOKENS.shadow.xs.value};
  display       : block;
  vertical-align: middle;
`;

export const PULSE_COLOR_APPLICATION = {
  buttonBackground : {
    pulseDefault : "#ffd84e",
    pulseHover   : "#ffc702",
    pulseFocus   : "#ffc702",
    pulsePressed : "#dead00",
    pulseDisabled: "#eaeaea",
  }
};

export const FONT_WEIGHTS = {
  poppins_0    : "500",
  poppins_1    : "600",
  poppins_2    : "700",
  poppins_3    : "800",
  roboto_mono_4: "500",
  roboto_mono_5: "700",
  roboto_7     : "500",
  roboto_9     : "700",
};

export const TITLES_NORMAL = {
  FT_01 : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_02 : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "32px",
    "font-size"        : "23px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_03 : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "34px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_04 : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "40px",
    "font-size"        : "32px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_05 : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "54px",
    "font-size"        : "36px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_06 : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "64px",
    "font-size"        : "42px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const LABEL_SMALL = {
  DEFAULT : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "line-height"      : "20px",
    "font-size"        : "14px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  DEFAULT_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "font-style"       : "italic",
    "line-height"      : "20px",
    "font-size"        : "14px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "font-style"       : "italic",
    "line-height"      : "20px",
    "font-size"        : "14px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "20px",
    "font-size"        : "14px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "font-style"       : "italic",
    "line-height"      : "20px",
    "font-size"        : "14px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "20px",
    "font-size"        : "14px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  MONOSPACE : {
    "font-family"      : "Roboto",
    "font-weight"      : "500",
    "line-height"      : "20px",
    "font-size"        : "14px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const LABEL_XS = {
  DEFAULT : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "line-height"      : "17px",
    "font-size"        : "12px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  DEFAULT_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "font-style"       : "italic",
    "line-height"      : "17px",
    "font-size"        : "12px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "font-style"       : "italic",
    "line-height"      : "17px",
    "font-size"        : "12px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "17px",
    "font-size"        : "12px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "font-style"       : "italic",
    "line-height"      : "17px",
    "font-size"        : "12px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "17px",
    "font-size"        : "12px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  MONOSPACE : {
    "font-family"      : "Roboto",
    "font-weight"      : "500",
    "line-height"      : "17px",
    "font-size"        : "12px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const PARAGRAPH = {
  DEFAULT : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "line-height"      : "24px",
    "font-size"        : "16px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  DEFAULT_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "font-style"       : "italic",
    "line-height"      : "24px",
    "font-size"        : "16px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "font-style"       : "italic",
    "line-height"      : "24px",
    "font-size"        : "16px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "24px",
    "font-size"        : "16px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "font-style"       : "italic",
    "line-height"      : "24px",
    "font-size"        : "16px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "24px",
    "font-size"        : "16px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  MONOSPACE : {
    "font-family"      : "Roboto",
    "font-weight"      : "500",
    "line-height"      : "24px",
    "font-size"        : "16px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const LABEL_LARGE = {
  DEFAULT : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  DEFAULT_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "font-style"       : "italic",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "font-style"       : "italic",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "font-style"       : "italic",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  MONOSPACE : {
    "font-family"      : "Roboto",
    "font-weight"      : "500",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const HEADER_SMALL = {
  DEFAULT : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "line-height"      : "32px",
    "font-size"        : "20px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  DEFAULT_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "font-style"       : "italic",
    "line-height"      : "32px",
    "font-size"        : "20px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "font-style"       : "italic",
    "line-height"      : "32px",
    "font-size"        : "20px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "32px",
    "font-size"        : "20px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "font-style"       : "italic",
    "line-height"      : "32px",
    "font-size"        : "20px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "32px",
    "font-size"        : "20px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  MONOSPACE : {
    "font-family"      : "Roboto",
    "font-weight"      : "500",
    "line-height"      : "32px",
    "font-size"        : "20px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const HEADER_LARGE = {
  DEFAULT : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "line-height"      : "32px",
    "font-size"        : "23px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  DEFAULT_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "font-style"       : "italic",
    "line-height"      : "36px",
    "font-size"        : "23px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "font-style"       : "italic",
    "line-height"      : "36px",
    "font-size"        : "23px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "36px",
    "font-size"        : "23px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "font-style"       : "italic",
    "line-height"      : "36px",
    "font-size"        : "23px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "36px",
    "font-size"        : "23px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  MONOSPACE : {
    "font-family"      : "Roboto",
    "font-weight"      : "400",
    "line-height"      : "36px",
    "font-size"        : "23px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "20px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const LABEL_XL = {
  DEFAULT : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "line-height"      : "40px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  DEFAULT_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "500",
    "font-style"       : "italic",
    "line-height"      : "40px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "font-style"       : "italic",
    "line-height"      : "40px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  BOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "600",
    "line-height"      : "40px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD_ITALIC : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "font-style"       : "italic",
    "line-height"      : "40px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  EXTRABOLD : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "40px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  MONOSPACE : {
    "font-family"      : "Roboto",
    "font-weight"      : "500",
    "line-height"      : "40px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const TITLES_MONO = {
  FT_01 : {
    "font-family"      : "Roboto",
    "font-weight"      : "700",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_02 : {
    "font-family"      : "Roboto",
    "font-weight"      : "700",
    "line-height"      : "32px",
    "font-size"        : "23px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_03 : {
    "font-family"      : "Roboto",
    "font-weight"      : "700",
    "line-height"      : "34px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_04 : {
    "font-family"      : "Roboto",
    "font-weight"      : "700",
    "line-height"      : "40px",
    "font-size"        : "32px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_05 : {
    "font-family"      : "Roboto",
    "font-weight"      : "700",
    "line-height"      : "54px",
    "font-size"        : "36px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_06 : {
    "font-family"      : "Roboto",
    "font-weight"      : "700",
    "line-height"      : "64px",
    "font-size"        : "42px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_07 : {
    "font-family"      : "Roboto",
    "font-weight"      : "700",
    "line-height"      : "AUTO",
    "font-size"        : "60px",
    "letter-spacing"   : "0px",
    "paragraph-spacing": "45px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const TITLES_BOLD = {
  FT_01 : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "28px",
    "font-size"        : "18px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_02 : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "32px",
    "font-size"        : "23px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_03 : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "34px",
    "font-size"        : "26px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_04 : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "40px",
    "font-size"        : "32px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_05 : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "51px",
    "font-size"        : "36px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
  FT_06 : {
    "font-family"      : "Poppins",
    "font-weight"      : "700",
    "line-height"      : "54px",
    "font-size"        : "42px",
    "letter-spacing"   : "0%",
    "paragraph-spacing": "0px",
    "paragraph-indent" : "",
    "text-case"        : "none",
    "text-decoration"  : "none",
  },
};

export const INTER_FONT = {
  PARAGRAPH : {
    "font-family": "Inter",
    "font-size"  : "14px",
    "font-style" : "normal",
    "font-weight": "400",
    "line-height": "20px"
  },
  TITLE: {
    "font-family": "Inter",
    "font-size"  : "24px",
    "font-style" : "normal",
    "font-weight": "600",
    "line-height": "32px",
  },
  LABEL: {
    "font-family": "Inter",
    "font-size"  : "10px",
    "font-style" : "normal",
    "font-weight": "500",
    "line-height": "16px"
  }
};
