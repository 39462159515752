import { logError } from 'App/services/coralogixService';
import { getUserId } from 'App/services/idpTokenService';
import { OPADecideContext, OPAOptimizelyDecision } from 'Shared/models/swagger/opa';
import { APIResponse, MS_API } from 'Shared/services/apiInterface';
import { OPA_FLAG, OPA_VARIABLE } from 'Shared/services/opa/constants';

// Interfaces
export interface SplitTest<Type=any> {
  enabled: boolean;
  variables: Partial<Record<OPA_VARIABLE, Type>>
}

// Promise cache type
type DecisionRequestCacheType = {
  [key: string]: {
    requestTime: number,
    promise: Promise<any>
  }
};

// The purpose of this cache is to reduce the number of
// requests to the OPA API by consolidating similar requests.
const decisionRequestCache: DecisionRequestCacheType = {};
const REQUEST_TTL                                    = 60 * 1000;

/**
 * Get the optimizely decisions from OPA but don't make too many requests
 * @param flagKeys
 * @returns
 */
export const getOptimizelyDecisions = (flagKeys: OPA_FLAG[]): Promise<OPAOptimizelyDecision[]> => {
  const flagKeysPromiseKey = flagKeys.join(',');

  if (decisionRequestCache[flagKeysPromiseKey] && decisionRequestCache[flagKeysPromiseKey].requestTime > (Date.now() - REQUEST_TTL)) {
    return decisionRequestCache[flagKeysPromiseKey].promise;
  }

  const userId = getUserId(true);

  // Don't get optimizely decisions if the user is not logged in
  if (!userId) {
    return Promise.resolve([]);
  }

  decisionRequestCache[flagKeysPromiseKey] = {
    requestTime: Date.now(),
    promise: new Promise(resolve => {
      const baseURL                = process.env.REACT_APP_OPA_BASE_URL;
      const flagKeyParameters      = flagKeys.map(key => {
        return `keys=${key}`;
      }).join('&');
      const url                    = `/v1/decide?${flagKeyParameters}`;
      const data: OPADecideContext = {
        decideOptions: ['ENABLED_FLAGS_ONLY'],
        userId: userId
      };

      MS_API({
        method : 'POST',
        baseURL: baseURL,
        url    : url,
        data   : data
      }).then((response: APIResponse<OPAOptimizelyDecision[] | OPAOptimizelyDecision>) => {
        const decisions: OPAOptimizelyDecision[] = Array.isArray(response.data) ? response.data : [response.data];
        resolve(decisions);
      }).catch(error => {
        logError('getOptimizelyDecisions', error);
        resolve([]);
      });
    })
  };

  return decisionRequestCache[flagKeysPromiseKey].promise;
};
