import { logError } from 'App/services/coralogixService';
import { getUserId } from 'App/services/idpTokenService';
import { VEROnfidoToken, VERUserVerificationStatus } from 'Shared/models/swagger/ver';
import { setUserCacheData, UserSessionCacheKeys } from 'Shared/services/sessionCacheService';
import { SwrKeys } from 'Shared/services/swr/swrKeys';
import {
  getUserVerificationOnfidoToken,
  getUserVerificationStatus,
  postUserVerificationOnfidoChecks
} from 'Shared/services/ver/api/verificationApi';
import useSWR, { useSWRConfig } from 'swr';

const userVerificationStatuses = {
  none      : 'NONE',
  mandated  : 'MANDATED',
  inProgress: 'IN_PROGRESS',
  passed    : 'PASSED',
  failed    : 'FAILED'
} as const;

type UserVerificationStatus = (typeof userVerificationStatuses)[keyof typeof userVerificationStatuses];

type OnfidoChecksResponseModel = {
  success: boolean
}

/**
 *
 * @returns {Promise<StatusResponseModel>}
 */
const getStatusFromUserVerification = (optional = false): Promise<VERUserVerificationStatus | null> => {
  if (optional && !getUserId(true)) {
    return Promise.resolve(null);
  }

  return new Promise<VERUserVerificationStatus>((resolve, reject) => {
    getUserVerificationStatus().then((response) => {
      setUserCacheData(UserSessionCacheKeys.USER_VERIFICATION_STATUS, response.data);
      resolve(response.data);
    }).catch(error => {
      logError('getUserVerificationStatus', error);
      reject(null);
    });
  });
};

/**
 *
 * @returns {Promise<VEROnfidoToken>}
 */
const getOnfidoTokenFromUserVerification = (): Promise<VEROnfidoToken> => {
  return new Promise<VEROnfidoToken>((resolve, reject) => {
    getUserVerificationOnfidoToken()
      .then((response) => {
        const onfidoTokenResponseModel = {
          token: response?.data?.token
        };

        resolve(onfidoTokenResponseModel);
      })
      .catch(error => {
        logError('getUserVerificationOnfidoToken', error);
        reject(null);
      });
  });
};

/**
 *
 * @returns {Promise<OnfidoChecksResponseModel>}
 */
const postOnfidoChecksUserVerification = (): Promise<OnfidoChecksResponseModel> => {
  return new Promise<OnfidoChecksResponseModel>((resolve, reject) => {
    postUserVerificationOnfidoChecks()
      .then(() => {
        const OnfidoChecksResponseModel = {
          success: true
        };

        resolve(OnfidoChecksResponseModel);
      })
      .catch(error => {
        logError('postUserVerificationOnfidoChecks', error);
        reject(null);
      });
  });
};

/**
 * @returns {Promise<StatusResponseModel>}
 * @param optional
 */
const useUserVerificationStatus = (optional = false) => {
  return useSWR(SwrKeys.UserVerificationStatus, () => getStatusFromUserVerification(optional));
};

const useRefetchUserVerificationStatus = () => {
  const { mutate } = useSWRConfig();
  return () => mutate(key => key === SwrKeys.UserVerificationStatus);
};

export {
  getOnfidoTokenFromUserVerification,
  getStatusFromUserVerification,
  OnfidoChecksResponseModel,
  postOnfidoChecksUserVerification,
  useRefetchUserVerificationStatus,
  UserVerificationStatus,
  userVerificationStatuses,
  useUserVerificationStatus
};
