import { getSessionId } from 'App/services/idpTokenService';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { AnalyticsEvent, analyticsTrack } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
} from 'Shared/services/analytics/constants';
import { setLocalStorage } from 'Shared/services/localStorageService';

/**
 * Triggered when a user starts auto login
 * @returns void
 */
export const analyticsTrackLoginStart = (subType: ANALYTICS_EVENT_SUB_TYPE): void => {
  // Store the session id of the current login session
  const sessionId = getSessionId();
  if (sessionId) {
    setLocalStorage(LOCAL_STORAGE_IDS.LAST_SESSION_ID, sessionId);
  }

  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.start,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.type]     : ANALYTICS_EVENT_TYPE.login,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type] : subType
    }
  };

  analyticsTrack([event]);
};