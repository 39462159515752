import React from 'react';
import { BannerHeightProvider } from 'Shared/context/bannerHeight';

/**
 * Renders all of the top-level react context providers for the app.
 * This component could render providers for global state, such as a common value used across the entire app.
 * This could be a piece of state we manage ourselves, or could at some point be managed by a state management library
 * such as react-redux's <Provider> or Recoil's <RecoilRoot>.
 * @param props The props, of which there are only `children`.
 * @returns The passed in `children` wrapped by all of the managed providers.
 */
export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <BannerHeightProvider>
      {children}
    </BannerHeightProvider>
  );
};
