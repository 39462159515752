export enum SwrKeys {
  UserPoints = 'userPoints',
  CategoryData = 'categoryData',
  UserInfoWithInviteId = 'userInfoWithInviteId',
  UserInfo = 'userInfo',
  UserFaq = 'userFaq',
  AllQuestions = 'allQuestions',
  Question = 'question',
  Questionnaire = 'questionnaire',
  Answers = 'answers',
  AnswersGP = 'answersGP',
  GetSurveys = 'getSurveys',
  CompletedActivities = 'completedActivities',
  MonolithLogin = 'monolith-login',
  CheckDeviceEligibility = 'checkDeviceEligibility',
  AeDirectSurvey = '<rdt>/v1/survey/<id>',
  AeEmailSurvey = '<rdt>/v1/survey',
  SendDeviceRequest = 'sendDeviceRequest',
  AuthTokenUsingCredentials = 'useAuthTokensUsingCredentials',
  LoginUsingAutoLoginKey = 'loginUsingAutoLoginKey',
  SendResetPassword = 'useSendResetPassword',
  VerifyDevice = 'verifyDevice',
  PrivacyPolicy = 'surveyjunkie-privacy-policy',
  InviteTerms = 'referral-terms',
  TermsAndConditions = 'surveyjunkie-terms-and-conditions',
  AgreementDocument = 'agreementDocument',
  PaymentOptions = 'paymentOptions',
  PaymentMethods = 'paymentMethods',
  UserStats = 'userStats',
  UserVerificationStatus = 'userVerificationStatus',
  UserPromotions = 'userPromotions',
  SplitTestSurveySortByConversion = 'split-test-survey-sort-by-conversion',
  UserActivitiesInLast24Hours = 'userActivitiesInLast24Hours',
  FirebaseRemoteConfig = 'firebaseRemoteConfig',
  LeaderBoard = 'leaderBoard',
  UserLeaderBoardPoints = 'userLeaderBoardPoints',
  MonolithPanelActivities = 'monolithPanelActivities',
}
